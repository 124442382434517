import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Seo from '../components/seo'
import GatsbyGoogleTag from "../components/gatsby-google-tag"
import { sanitizeHtmlContent, truncateText } from '../components/utils/utils'

import TritonDefaultLayout from '../layout/default-layout'
import SectionHero from '../components/sections/SectionHero/SectionHero'
import ContentHeadline from '../components/content/content-headline'

import { 
  Container,
  Segment,
} from 'semantic-ui-react';

dayjs.extend(utc);

export const Head = ({ location, pageContext }) => {
  //console.debug('pages - head', { location, params, data, pageContext });
  const { htmlBody, articleBody, publicationDate, publishedUtc } = pageContext.page;
  // truncate text for seo description
  const truncatedArticleText = (htmlBody && htmlBody.html) ? truncateText(sanitizeHtmlContent(htmlBody.html), 10) : articleBody && articleBody.html ? truncateText(sanitizeHtmlContent(articleBody.html), 10) : null;
  // handle page level seo content
  const seo = {
    pageTitle: pageContext.page.displayText+' | News | Triton Digital',
    pageDescription: truncatedArticleText ? truncatedArticleText : null,
  }
  return (
    <>
      <Seo pagePath={location.pathname} ogType={'article'} publishedUtc={publicationDate ? publicationDate : publishedUtc ? publishedUtc : null} title={seo.pageTitle} description={seo.pageDescription} />
      <GatsbyGoogleTag />
    </>
  )
}

const PagesNewsItem = (props) => {
  //console.debug('PagesNewsItem - props', props);

  if (typeof window !== 'undefined') {
    document.body.id = 'pages-news-item';
  }

  const {
    //contentItemId,
    //contentType,
    displayText,
    publicationDate, 
    publishedUtc,
    authorInfo,
    htmlBody,
    authorName,
    articleBody,
  } = props.pageContext.page;

  const dateRender = publicationDate ? dayjs.utc(publicationDate).format('MMMM DD, YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM DD, YYYY') : null;

  return (
    <>
      <TritonDefaultLayout subpage={'news-item'}>
        <SectionHero 
          commonPageSection={{
            "dOMID": "top",
            "textColor": "white",
            "textAlign": "left",
            "backgroundColor": "linear-gradient(225deg, rgb(254, 125, 76) 0%, rgb(224, 111, 126) 11%, rgb(148, 60, 215) 31%, rgb(75, 0, 161) 53%, rgb(93, 44, 208) 72%, rgb(18, 175, 222) 100%)",
          }}
          contentCollection={{
            contentItems: [
              {
                "contentItemId": "4hp70xdbw2ycwvfvhrdvhvmtcb",
                "contentType": "ContentHeadline",
                "displayText": "News Item Page Headline",
                "variationType": "h3",
                "textAlign": "left",
                "preheader": "",
                "content": {
                  "html": ""
                },
                "subheader": null,
                "maxWidth": "100%"
              }
            ]
          }} 
        />
        <Segment basic className={'news-item-content'}>
          {htmlBody && htmlBody.html ?
            <Container text>
              <Segment basic>
                {displayText ?
                  <ContentHeadline variationType={'h1'} preheader={'News'} content={{html:displayText}} />
                :
                  null
                }
                {authorInfo && authorInfo.html && dateRender ?
                  <ContentHeadline variationType={'h4'} content={{html:authorInfo.html}} subheader={dateRender} />
                :
                  null
                }
                <Segment basic dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(htmlBody.html)}}></Segment>
              </Segment>
            </Container>
          : articleBody && articleBody.html ?
            <Container text>
              <Segment basic>
                {displayText ?
                  <ContentHeadline variationType={'h1'} preheader={'News'} content={{html:displayText}} />
                :
                  null
                }
                {authorName && dateRender ?
                  <ContentHeadline variationType={'h4'} content={{html:authorName}} subheader={dateRender} />
                :
                  null
                }
                <Segment basic dangerouslySetInnerHTML={{__html: sanitizeHtmlContent(articleBody.html)}}></Segment>
              </Segment>
            </Container>
          :
            null
          }
        </Segment>
      </TritonDefaultLayout>
    </>
  )
}

export default PagesNewsItem
